<template>
  <div class="about">
    <div class="columns" id="mail-app">
      <div class="column is-3 is-2-widescreen has-background-white-ter">
        <new-message class="mt-5 mb-3 mx-3">
          <span class="button is-primary is-block is-bold">Compose</span>
        </new-message>
        <div :class="{ 'most-of-screen': isDesktop }">
          <sidebar-menu @select-folder="replaceData" />
        </div>
      </div>
      <div
        v-if="!isShowingMessage || isDesktop"
        class="column is-9 is-4-desktop messages is-fullheight has-background-white-bis"
      >
        <div class="is-clearfix mb-2">
          <div class="field is-grouped is-pulled-right">
            <div class="control has-icons-left">
              <div class="select is-small">
                <select v-model="sort">
                  <option value="date" :selected="sort === 'date'">Sort by Date</option>
                  <option value="name" :selected="sort === 'name'">Sort by Company Name</option>
                </select>
              </div>
              <span class="icon is-small is-left">
                <i class="fas fa-sort"></i>
              </span>
            </div>
            <p class="control">
              <a class="button is-small" @click="loadSpam" :class="{ 'is-loading': loading }">
                <i class="fa fa-sync"></i>
              </a>
            </p>
          </div>
        </div>
        <div style="position: relative">
          <div :class="{ 'most-of-screen': isDesktop }" class="mt-5">
            <div v-if="sortedMessages.length > 0">
              <transition-group tag="div" name="list">
                <div
                  v-for="(msg, index) in sortedMessages"
                  :key="index"
                  class="card pointer mb-2"
                  :class="{ 'has-background-link-light': msg === message }"
                  @click="showMessage(msg)"
                >
                  <div class="card-content is-size-6">
                    <div class="msg-timestamp is-pulled-right is-size-7">{{ formatDate(msg.date) }}</div>
                    <div v-if="!!msg.company">
                      <h2 class="has-text-weight-bold">{{ msg.company }}</h2>
                    </div>
                    <h4 class="has-text-weight-medium is-size-7">
                      {{ msg.from }}
                    </h4>
                    <h3 class="mt-3 has-text-weight-medium">
                      {{ msg.subject }}
                    </h3>
                    <p class="ellipsis">{{ msg.content }}</p>
                  </div>
                </div>
              </transition-group>
            </div>
            <div v-else class="card">
              <div class="card-content has-text-centered">
                <h2>This folder is currently empty</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="column is-9 is-5-desktop is-6-widescreen is-fullheight">
        <transition name="fade" mode="out-in">
          <div v-if="message" :key="String(message.date)">
            <div class="columns is-mobile mt-3 mb-0">
              <div class="column is-11">
                <div class="field is-grouped">
                  <div class="field has-addons mr-3">
                    <p class="control">
                      <new-message :address="message.address">
                        <span class="button is-small"><i class="fa fa-reply"></i></span>
                      </new-message>
                    </p>
                    <p class="control">
                      <new-message :address="message.address">
                        <span class="button is-small"><i class="fa fa-reply-all"></i></span>
                      </new-message>
                    </p>
                  </div>
                  <p class="control">
                    <new-message>
                      <a class="button is-small"><i class="fa fa-share"></i></a>
                    </new-message>
                  </p>
                  <p class="control">
                    <a class="button is-small"><i class="fa fa-flag"></i></a>
                  </p>
                </div>
              </div>
              <div class="column is-1">
                <p class="control is-pulled-right pr-1">
                  <a class="button is-small is-danger" @click="deleteMessage(message)"><i class="fa fa-trash"></i></a>
                </p>
              </div>
            </div>
            <div class="pt-3 pb-5 px-1 is-hidden-desktop has-text-centered">
              <a class="button is-primary is-fullwidth is-outlined" @click="closeMessage()">
                <i class="fa fa-arrow-alt-circle-left mr-2"></i>Back to list</a
              >
            </div>
            <full-message :message="message" />
          </div>
          <div v-else>
            <div class="hero is-halfheight">
              <div class="hero-body has-text-centered">
                <div class="container">
                  <div class="content">Please select a message on the left</div>
                </div>
              </div>
            </div>
          </div>
        </transition>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { debounce, orderBy, truncate } from 'lodash'
import { computed, defineComponent, ref, reactive, watch } from 'vue'
import type { Ref } from 'vue'
// import { useRouter, useRoute } from 'vue-router'

import NewMessage from '../components/NewMessage.vue'
import SidebarMenu from '../components/SidebarMenu.vue'
import FullMessage from '../components/FullMessage.vue'

import { Message, academic, education, initial, professional, skills, spam } from '../data'


export default defineComponent({
  name: 'MailView',
  components: {
    NewMessage,
    SidebarMenu,
    FullMessage
  },
  setup() {
    const sort: Ref<string> = ref('date')
    const message: Ref<Message | null> = ref(null)
    const loading = ref(false)
    const messages: Array<Message> = reactive([])

    // const router = useRouter()
    // const route = useRoute()

    const isDesktop = ref(window.innerWidth >= 1024)
    window.addEventListener('resize', function () {
      isDesktop.value = window.innerWidth >= 1024
    })

    const pushToMessages = function (what: Array<Message>) {
      for (const msg of what) {
        messages.push(msg)
      }
      // message.value = sortedMessages.value[0]
    }
    pushToMessages(initial)

    const formatDate = function (date: Date) {
      return new Intl.DateTimeFormat('en-US').format(date)
    }

    const closeMessage = function () {
      message.value = null
      // router.push({ params: { id: null }})
    }
    const showMessage = function (msg: Message) {
      message.value = msg
    }

    const deleteMessage = function (msg: Message) {
      const i = messages.indexOf(msg)
      message.value = null
      messages.splice(i, 1)
      // router.push({ params: { id: null }})
    }
    const isShowingMessage = computed(() => message.value !== null)

    const loadSpam = function () {
      const returnSomething: number = Math.floor(Math.random() * Math.floor(2))
      loading.value = true
      setTimeout(
        debounce(() => {
          if (returnSomething === 1) {
            for (const mail of spam) {
              messages.splice(0, 0, mail)
            }
          }
          loading.value = false
        }, 500),
        500
      )
    }

    const replaceData = function (to: string) {
      closeMessage()
      messages.splice(0, messages.length)
      switch (to) {
        case 'inbox':
          pushToMessages(initial)
          break
        case 'professional':
          pushToMessages(professional)
          break
        case 'academic':
          pushToMessages(academic)
          break
        case 'skills':
          pushToMessages(skills)
          break
        case 'education':
          pushToMessages(education)
          break
      }
    }

    const truncateMessage = function (msg: string) {
      return truncate(msg, { length: 150 })
    }

    const sortedMessages = computed(() => {
      if (sort.value === 'date') {
        return orderBy(messages, ['date', 'name'], ['desc', 'asc'])
      } else {
        return orderBy(messages, ['name', 'date'], ['asc', 'asc'])
      }
    })

    // message.value = sortedMessages.value[0]

    // watch(() => route.params.id,
    //   async newId => {
    //     console.log('router', newId)
    //     if (typeof newId == 'string') {
    //       const msg:Message = messages[parseInt(newId)]
    //       console.log(msg)
    //       message.value = msg
    //     }
    //   })

    return {
      loading,
      message,
      formatDate,
      closeMessage,
      deleteMessage,
      showMessage,
      sortedMessages,
      sort,
      isShowingMessage,
      loadSpam,
      replaceData,
      truncateMessage,
      isDesktop
    }
  }
})
</script>

<style scoped>
.ellipsis {
  display: block;
  /* Fallback for non-webkit */
  display: -webkit-box;
  height: 3rem;
  /* Fallback for non-webkit */
  margin: 0 auto;
  font-size: 0.75rem; /* Bulma size-7 */
  line-height: 1.4;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.messages {
  display: block;
  background-color: #fff;
  border-right: 1px solid #dedede;
  padding: 2rem 1rem;
}
aside span.icon {
  margin-right: 0.25rem;
}
div.card.pointer {
  cursor: pointer;
}

.list-enter-active,
.list-leave-active,
.list-move {
  transition: 500ms cubic-bezier(0.59, 0.12, 0.34, 0.95);
  transition-property: opacity, transform;
}

.list-enter {
  opacity: 0;
  transform: translateX(50px) scaleY(0.5);
}

.list-enter-to {
  opacity: 1;
  transform: translateX(0) scaleY(1);
}

.list-leave-active {
  position: absolute;
}

.list-leave-to {
  opacity: 0;
  transform: scaleY(0);
  transform-origin: center top;
}
</style>
