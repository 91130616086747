<template>
  <div ref="theChart" id="chart" style="min-height: 30rem; min-width: 20rem" />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch, reactive } from 'vue'
import { newPlot, react, Layout } from 'plotly.js'

export default defineComponent({
  name: 'HandsOnDev',
  setup() {
    const theChart = ref('#chart')
    const data: Record<string, any> = reactive({
      type: 'bar',
      x: [9, 4, 4, 5, 7, 8.5, 8.5, 9.5, 9.5, 9, 10],
      y: [
        'Shells (mostly POSIX, bash, fish)',
        'C/C++',
        'Ruby/Rails',
        'Java',
        'CSS/SASS',
        'Python & Django & DRF & FastAPI',
        'Javascript & Vue.js/React',
        'CI/CD (GitLab, Jenkins, GitHub Action)',
        'Virtualization (Docker, K8S, LXD)',
        'Open Source Development',
        'Software Architecture'
      ],
      marker: {
        color: 'rgba(50,171,96,0.6)',
        line: {
          color: 'rgba(50,171,96,1.0)',
          width: 1
        }
      },
      orientation: 'h'
    })
    const layout: Partial<Layout> = {
      hovermode: false,
      dragmode: false,
      margin: {
        l: 250,
        r: 50,
        t: 0,
        b: 20
      },
      font: {
        family: 'Inter'
      },
      showlegend: false
    }

    onMounted(() => {
      newPlot(theChart.value, [data], layout, { displayModeBar: false, responsive: true })
    })

    watch(
      data,
      (data) => {
        react(theChart.value, data.value, layout)
      },
      { deep: true }
    )
    return { theChart }
  }
})
</script>

<style></style>
