<template>
  <div ref="theChart" id="chart" style="min-height: 20rem; min-width: 20rem" />
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch, reactive } from 'vue'
import { newPlot, react, Layout } from 'plotly.js'

export default defineComponent({
  name: 'SkillOverview',
  setup() {
    const theChart = ref('#chart')
    const data: Record<string, any> = reactive({
      type: 'scatterpolar',
      r: [5, 5, 4, 4, 5, 4, 4, 5],
      theta: [
        'Problem Solving',
        'Requirements & Architecture',
        'Testing',
        'Docker, K8s, DevOps, ...',
        'Team Work & Leadership',
        'Full Stack Development',
        'Usability, UX',
        'Problem Solving'
      ],
      fill: 'toself'
    })
    const layout: Partial<Layout> = {
      hovermode: false,
      dragmode: false,
      polar: {
        radialaxis: {
          visible: true,
          range: [0, 5]
        }
      },
      margin: {
        l: 120,
        r: 120,
        t: 20,
        b: 20
      },
      font: {
        family: 'Inter'
      },
      showlegend: false
    }

    onMounted(() => {
      newPlot(theChart.value, [data], layout, { displayModeBar: false, responsive: true })
    })

    watch(
      data,
      (data) => {
        react(theChart.value, data.value, layout)
      },
      { deep: true }
    )
    return { theChart }
  }
})
</script>

<style></style>
