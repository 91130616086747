<template>
  <div id="app">
    <nav class="navbar has-shadow" role="navigation" aria-label="main navigation">
      <div class="container">
        <div class="navbar-brand">
          <a
            role="button"
            aria-label="menu"
            :aria-expanded="menuOpen"
            class="navbar-burger burger"
            :class="{ 'is-active': menuOpen }"
            data-target="navMenu"
            @click="toggleMenu"
          >
            <span></span>
            <span></span>
            <span></span>
          </a>
        </div>
        <div class="navbar-menu" :class="{ 'is-active': menuOpen }">
          <div class="navbar-end">
            <div class="navbar-item has-dropdown is-hoverable">
              <a class="navbar-link"> Account </a>
              <div class="navbar-dropdown">
                <a class="navbar-item"> Profile </a>
                <a class="navbar-item"> Settings </a>
              </div>
            </div>
            <div class="navbar-item">
              <div class="buttons">
                <a class="button is-light"> Log out </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="ml-5">
      <router-view />
    </div>
    <footer class="my-footer">
      <div class="container">
        <div class="content has-text-centered pb-3">
          <p>
            <span class="has-text-weight-bold">Thorsten Merten's Personal Resume.</span>
            <br class="is-hidden-desktop" />
            <span>
              An experiment with
              <a href="https://vuejs.org" target="_blank">Vue JS</a> 3's
              <a href="https://composition-api.vuejs.org/api.html" target="_blank">composition API</a> and
              <a href="https://www.typescriptlang.org/" target="_blank">TypeScript</a>.
              <a href="https://bulma.io" target="_blank" class="ml-2">
                <img
                  :src="require('./assets/made-with-bulma.png')"
                  target="_blank"
                  alt="Made with Bulma"
                  width="128"
                  height="24"
                  style="margin-bottom: -0.3rem"
                />
              </a>
            </span>
          </p>
        </div>
      </div>
    </footer>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, Ref } from 'vue'

export default defineComponent({
  name: 'App',
  setup() {
    const menuOpen: Ref<boolean> = ref<boolean>(false)
    const toggleMenu = () => {
      menuOpen.value = !menuOpen.value
    }
    return { menuOpen, toggleMenu }
  }
})
</script>

<style lang="scss">
html,
body {
  height: 100%;
  margin: 0;
}
#app {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.nowrap {
  white-space: nowrap;
}
</style>

<style lang="scss" scoped>
.my-footer {
  border-top: 1px solid #dedede;
  margin-top: 0.75rem;
  padding-top: 1rem;
  margin-bottom: 1rem;
}
</style>
