import { reactive } from 'vue'
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import LoginView from '../views/LoginView.vue'
import MailView from '../views/MailView.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/login',
    name: 'Login',
    component: LoginView
  },
  {
    path: '/folder/:folder(inbox|sent|professional|academic|education|skills|important)',
    name: 'mailFolder',
    // props: route => {
    //   console.info('route as prop', route.params.folder)
    //   return { folder: route.params.folder }
    // },
    // component: () => import(/* webpackChunkName: "about" */ '../views/Mail.vue')
    component: MailView
  },
  {
    path: '/',
    name: 'mail',
    redirect: () => '/folder/inbox'
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export const routeData = reactive({ params: {}, query: {} })

router.afterEach((route) => {
  routeData.params = route.params
  routeData.query = route.query
})

export default router
