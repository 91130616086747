<template>
  <div :class="{ 'most-of-screen': isDesktop }">
    <div class="box" style="overflow: auto">
      <div class="columns">
        <div class="column is-narrow">
          <div class="avatar">
            <img v-if="message.image" width="64" height="64" :src="`${baseUrl}${message.image}`" />
            <img v-else src="https://placehold.it/64x64" />
          </div>
        </div>
        <div class="column">
          <div class="address">
            <div v-if="!!message.company" class="has-text-weight-bold">{{ message.company }}</div>
            <div class="name">{{ message.from }} &lt;{{ message.address }}&gt;</div>
          </div>
        </div>
      </div>
      <h1 class="has-text-weight-bold is-size-5 mb-2">{{ message.subject }}</h1>
      <div class="content" v-html="compiledMarkdown" />
      <template v-if="message.renderSpecial">
        <component :is="message.renderSpecial" />
      </template>
      <hr v-if="message.tags.length > 0" />
      <span v-for="tag in message.tags" :key="tag" class="tag is-info is-light mr-2">{{ tag }}</span>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, PropType, ref } from 'vue'
import { marked } from 'marked'
// const { marked } = require('marked')

import type { Message } from '../data'
import SkillOverview from './SkillOverview.vue'
import HandsOnDev from './HandsOnDev.vue'

export default defineComponent({
  name: 'FullMessage',
  components: { SkillOverview, HandsOnDev },
  props: {
    message: {
      type: Object as PropType<Message>,
      required: true
    }
  },
  setup(props) {
    const baseUrl: string = process.env.BASE_URL
    const isDesktop = ref(window.innerWidth >= 1024)
    window.addEventListener('resize', function () {
      isDesktop.value = window.innerWidth >= 1024
    })
    const compiledMarkdown = computed(() => {
      return marked.parse(props.message.content)
    })
    return { baseUrl, props, compiledMarkdown, isDesktop }
  }
})
</script>
