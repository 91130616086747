import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = {
  class: "box",
  style: {"overflow":"auto"}
}
const _hoisted_2 = { class: "columns" }
const _hoisted_3 = { class: "column is-narrow" }
const _hoisted_4 = { class: "avatar" }
const _hoisted_5 = ["src"]
const _hoisted_6 = {
  key: 1,
  src: "https://placehold.it/64x64"
}
const _hoisted_7 = { class: "column" }
const _hoisted_8 = { class: "address" }
const _hoisted_9 = {
  key: 0,
  class: "has-text-weight-bold"
}
const _hoisted_10 = { class: "name" }
const _hoisted_11 = { class: "has-text-weight-bold is-size-5 mb-2" }
const _hoisted_12 = ["innerHTML"]
const _hoisted_13 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({ 'most-of-screen': _ctx.isDesktop })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createElementVNode("div", _hoisted_4, [
            (_ctx.message.image)
              ? (_openBlock(), _createElementBlock("img", {
                  key: 0,
                  width: "64",
                  height: "64",
                  src: `${_ctx.baseUrl}${_ctx.message.image}`
                }, null, 8, _hoisted_5))
              : (_openBlock(), _createElementBlock("img", _hoisted_6))
          ])
        ]),
        _createElementVNode("div", _hoisted_7, [
          _createElementVNode("div", _hoisted_8, [
            (!!_ctx.message.company)
              ? (_openBlock(), _createElementBlock("div", _hoisted_9, _toDisplayString(_ctx.message.company), 1))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_10, _toDisplayString(_ctx.message.from) + " <" + _toDisplayString(_ctx.message.address) + ">", 1)
          ])
        ])
      ]),
      _createElementVNode("h1", _hoisted_11, _toDisplayString(_ctx.message.subject), 1),
      _createElementVNode("div", {
        class: "content",
        innerHTML: _ctx.compiledMarkdown
      }, null, 8, _hoisted_12),
      (_ctx.message.renderSpecial)
        ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.message.renderSpecial), { key: 0 }))
        : _createCommentVNode("", true),
      (_ctx.message.tags.length > 0)
        ? (_openBlock(), _createElementBlock("hr", _hoisted_13))
        : _createCommentVNode("", true),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.message.tags, (tag) => {
        return (_openBlock(), _createElementBlock("span", {
          key: tag,
          class: "tag is-info is-light mr-2"
        }, _toDisplayString(tag), 1))
      }), 128))
    ])
  ], 2))
}