export type Message = {
  company: null | string
  image: null | string
  from: string
  date: Date
  subject: string
  content: string
  address: string
  tags: string[]
  renderSpecial: null | string
}

export type Messages = Message[]

const date: Date = new Date()

export const initial: Messages = [
  {
    company: null,
    from: 'Dr. Thorsten Merten',
    image: 'images/merten_square.jpg',
    date: date.setDate(date.getDate() - 1) as unknown as Date,
    subject: 'How to use this app?',
    content: `Hi there,

Thank you for your interest in my CV.

Find my data sorted in the folders on the left (on mobile click the "change folder" button). Choose a folder to see mails relevant to my professional experience, education, (technical) skills etc.

**Please note that you are not looking at actual emails, the email content is a work of fiction, which I use to showcase my CV**.
However, I am trying hard to keep this as accurate as possible and email contents are tightly aligned with the respective colleagues where possible.

Kind regards,<br/>
Thorsten

PS: Weird, but whenever I delete any mails and reload this page the data reappears (I guess the mail app has a bug and does not propagate the delete to the database; I wonder who codes like this).`,
    address: 'thorsten@[this domain]',
    renderSpecial: null,
    tags: ['Instructions', 'Markdown', 'Vue', 'Composition Api', 'UX']
  }
]

export const academic: Array<Message> = [
  {
    company: 'Uni Heidelberg',
    from: 'Prof. Dr. Barbara Paech',
    image: 'images/uni-hd.jpg',
    date: new Date(),
    subject: 'PhD (Dr. rer. nat.), Uni HD Software Engineering awarded 02/17',
    content: `Dear Thorsten,

we enjoyed having you as an external PhD Student at the [Software Engineering Group](https://se.ifi.uni-heidelberg.de/people.html) in Heidelberg.

In your PhD thesis - [Identification of Software Features in Issue Tracking System Data](https://archiv.ub.uni-heidelberg.de/volltextserver/22655/) - you fused [Mining Software Repositories (MSR)](http://www.msrconf.org/) with [natural language processing](https://en.wikipedia.org/wiki/Natural_language_processing).
MSR is the science of using data mining and text mining techniques on software engineering data, e.g. to categorize the data, to locate software faults, or to predict future challenges.

Overall the thesis is about automatic classification of software feature descriptions vs bugs and finding and links (traces) between different issues.

Together with the software engineering group, we created the following papers in the context of your thesis:

* [Research Preview: Automatic Data Categorization in Issue Tracking Systems](http://subs.emis.de/LNI/Proceedings/Proceedings220/128.pdf) _by T. Merten and B. Paech (2013), GI Lecture Notes in Informatics_
* [Classifying Unstructured Data into Natural Language Text and Technical Information](http://dl.acm.org/citation.cfm?id=2597112) _by T. Merten, B. Mager, S. Bürsner, and B. Paech (2014) at MSR2014_
* [What Are the Features of This Software? An Exploratory Study](http://www.thinkmind.org/index.php?view=article&articleid=icsea_2014_4_30_10270) _by B. Paech, P. Hübner, T. Merten (2014) at ICSEA 2014_
* [Requirements Communication in Issue Tracking Systems in Four Open-Source Projects](http://ceur-ws.org/Vol-1342/02-reprico.pdf) _by T. Merten, B. Mager, P. Hübner, T. Quirchmayr, B. Paech, S. Bürsner at RePriCo 2015_
* [Do Information Retrieval Algorithms for Automated Traceability Perform Effectively on Issue Tracking System Data?](http://link.springer.com/chapter/10.1007/978-3-319-30282-9_4) _by T. Merten, D. Krämer, B. Mager, P. Schell, S. Bürsner, B. Paech at REFSQ 2016_
* [Software Feature Request Detection in Issue Tracking Systems](https://ieeexplore.ieee.org/document/7765522) _by T. Merten, M. Falis, P. Hübner, T. Quirchmayr, S. Bürsner and B. Paech_
`,
    address: 'barbara@uni-hd.example.com',
    renderSpecial: null,
    tags: ['NLP', 'Statistical Analytics', 'Machine Learning', 'Python', 'NLTK', 'Scikit Learn', 'Java']
  },
  {
    company: 'University of Applied Sciences Bonn-Rhein-Sieg',
    from: 'Prof. Dr. Simone Bürsner',
    image: 'images/h-brs.jpg',
    date: new Date(2016, 8, 15),
    subject: 'Researcher, then PhD Candidate "Requirements Engineering" from 07/09 to 07/16',
    content: `Hi Thorsten,

here is a quick summary of the work you did at H-BRS:

**Research and Requirements Engineering**

Together we implemented the [RESC 2010: 1st Workshop on Requirements Engineering in Small Companies](http://www.econstor.eu/obitstream/10419/58169/1/71601842X.pdf#page=135).
The RESC workshop investigated in problems and solutions for RE in smaller companies. Two of the workshop papers analyzed the typical situations in small and medium sized enterprises (SME) and two more papers presented solutions for standardization and bidding problems in SMEs.

You designed and developed the [Redmine RE](https://github.com/tmerten/redmine_re) project with lead to a lot of publications such as
* [Using RE knowledge to assist automatically during requirement specification](http://ieeexplore.ieee.org/xpls/abs_all.jsp?arnumber=6360068) _by T. Merten, T. Schäfer, and S. Bursner (2012) at REET2012_
* [Tooling zur schrittweisen methodischen Fundierung des RE in KMU]() _by T. Merten and S. Bürsner (2012)_
* [Improved representation of traceability links in requirements engineering knowledge using Sunburst and Netmap visualizations](http://ieeexplore.ieee.org/xpls/abs_all.jsp?arnumber=6046557) _by T. Merten, D. Jüppner, and A. Delater (2011) at MARK2011_
* [Towards a New Understanding of Small and Medium Sized Enterprises in Requirements Engineering Research](http://link.springer.com/chapter/10.1007/978-3-642-19858-8_7) _by T. Merten, K. Lauenroth, S. Bürsner (2011) at REFSQ2011_

**Teaching, Courses, Thesis Supervision, and Seminars**

* Masters seminar: Mining Software Repositories
* Supervision of four student and graduate assistants
* Supervision of ten bachelor’s and eight master’s theses (three of which received special awards; e.g. by the [FBTI](https://fbti.de/))
* Lecture and lab: Object Oriented Software Development with Ruby on Rails

PS: And you secured a three year scholarship of the [BRS graduate institute](https://www.h-brs.de/de/inf/news/glueckwunsch-zur-promotion-thorsten-merten).
`,
    address: 'simone@h-brs.example.com',
    renderSpecial: null,
    tags: [
      'Ruby on Rails',
      'Javascript',
      'Open Source',
      'Redmine Plugins',
      'Requirements',
      'Teaching',
      'Thesis Supervision'
    ]
  },
  {
    company: 'University of Bonn',
    from: 'Prof. Dr. Sören Auer (Head of Department)',
    image: 'images/uni-bonn.jpg',
    date: new Date(2017, 2, 10),
    subject: 'Senior Researcher from 11/16 to 02/17',
    content: `Dear Thorsten,

at University of Bonn you worked in the [OpenBudgets](https://okfn.de/projekte/openbudgets/) project. Here you mainly did

* open/public budget and spending data visualization and understanding
* budget fraud detection using machine learning and statistical analysis

This job was part time next to your job at Fraunhofer IAIS.`,
    address: 'soeren@uni-bonn.example.com',
    renderSpecial: null,
    tags: ['Data Analytics', 'Machine Learning', 'Data Visualization', 'Javascript', 'Project Management']
  }
]

export const education: Messages = [
  {
    company: 'University of Heidelberg',
    from: 'Secretary',
    address: 'secretary@uni-hd.example.com',
    image: 'images/uni-hd.jpg',
    date: new Date(2017, 2, 30),
    subject: 'Your PhD certificate',
    content: `Thorsten,

you successfully defended your PhD thesis with the topic
**[Identification of Software Features in Issue Tracking System Data](https://archiv.ub.uni-heidelberg.de/volltextserver/22655/)**.

Congrats!

PS: See Barabara's mail in the 'Academic Experience' folder for more info.`,
    renderSpecial: null,
    tags: ['Research', 'Conferences', 'Public Speaking', 'Academic Writing']
  },
  {
    company: 'University of Applied Sciences Bonn-Rhein-Sieg',
    from: 'Secretary FB02',
    address: 'secretary@h-brs.example.com',
    image: 'images/h-brs.jpg',
    date: new Date(2008, 12, 17),
    subject: 'Your Masters certificate',
    content: `Thorsten,

you can now pick up your masters certificate. You successfully defended your thesis
**"A Software Metrics Based Approach to Automatic Source Code Evaluation"**, which you did in collaboration with serie a logistics solutions AG.

Congrats!`,
    renderSpecial: null,
    tags: [
      'PhD Thesis',
      'Magna Cum Laude'
    ]
  },
  {
    company: 'York University',
    from: 'International Exchange Office',
    image: 'images/yorku.jpg',
    date: new Date(2008, 1, 4),
    subject: 'Software Engineering Exchange 06/07 - 01/08',
    content: `Hi Thorsten,

It is getting really cold in Canada and your exchange semester is over. The following credits can be transferred to your home university:

* Software Re-Engineerging (software metrics and analytics)
* Advanced Data Structures (self adaptive data structures and NP hard problems)
* Programming in C#
* Computer Vision

Use the attached documents to transfer your records.`,
    address: 'intl.exchange@yorku.example.com',
    renderSpecial: null,
    tags: [
      'Software Engineering',
      'Software Metrics',
      'Software Analytics',
      'Data Structures',
      'Computer Vision',
      'Language',
      'Inter Cultural Communication'
    ]
  },
  {
    company: 'University of Applied Sciences Bonn-Rhein-Sieg',
    from: 'Secretary FB02',
    image: 'images/h-brs.jpg',
    date: new Date(2006, 2, 24),
    subject: 'Your Bachelors certificate',
    content: `Thorsten,

you can now pick up your bachelors certificate. You successfully defended your thesis
**"Localizing Text in Video Frames and Preparation for OCR"**, which you did in collaboration with Fraunhofer IAIS NetMedia.

Congrats!`,
    address: 'simone.buersner@example.com',
    renderSpecial: null,
    tags: [
      'Bachelor of Science'
    ]
  },
  {
    company: 'Rheinische Akademie e.V (now gGmbH)',
    from: 'RAK Info',
    address: 'rak@rak.example.com',
    image: 'images/rak.jpg',
    date: new Date(2000, 6, 27),
    subject: 'Your A-Levels certificate',
    content: `Hi Thorsten,

Your A-Level certificate is ready. The Rheinische Akademie is a school combining A-level and computer science apprenticeships.
Besides your A-Level you earned your skills in programming C/C++, networking, databases, and basic electronic circuit design.`,
    renderSpecial: null,
    tags: ['C', 'C++', 'networking', 'SQL', 'electronics']
  }
]

export const professional: Messages = [
  {
    company: 'Canonical',
    from: 'Mark (CEO)',
    image: 'images/canonical-logo.png',
    date: date.setDate(date.getDate() - 2) as unknown as Date,
    subject: 'Software Engineering Manager from 10/2022',
    content: `Hi Thorsten,

I appreciate your work on [MAAS](https://maas.io). MAAS is a complex beast and it carries quite some technical debt with respect to its
database design and its execution architecture. Besides adding new features, the MAAS team is systematically cleaning up this technical debt
and resolving old issues, to be able to move forward more quickly in the future.

In addition to the work on MAAS, you and your team are bootstrapping a new - not yet publicly announced - project.

Your role is to lead the developers, define and discuss software architectures and improve the planning and cleanup processes.
All engineering managers at Canonical should also be role models for their developers and have a deep technical understanding of
the programming lanugages used, software architectures and engineering processes. You are leading people from front-end (React)
to back-end developers (Python) and across the networking stack.

Best,<br/>
Mark`,
    address: 'mark@canonical.example.com',
    renderSpecial: null,
    tags: [
      'Team Leading',
      'Strategic Planning',
      'Product Management',
      'Requirements Engineering',
      'Roadmap Planning',
      'Software Architecture',
      'MAAS',
      'LXD',
      'Javascript',
      'React',
      'HTML/CSS',
      'Python',
      'Django',
      'FastAPI',
      'Websockets',
      'Docker',
      'IP',
      'BMCc',
      'Networking',
      'DPUs/GPUs',
      'Bare Metal Orchestration'
    ]
  },
  {
    company: 'sustainabill GmbH',
    from: 'Klaus Wiesen (CEO)',
    image: 'images/sustainabill.jpg',
    date: new Date(2022, 10, 31),
    subject: 'sustainabill CTO 03/2017 to 10/2022',
    content: `Hi Thorsten,

it has been more than five years, that we were working together. It has been quite a journey with sustainabill and it still is super exiting.

In the early days of sustainabill you designed a solid technical stack, bootstrapped the whole platform together with Christoph and created some MVPs
to evaluate carbon assessment strategies.

As we matured, you were responsible for aligning the technical road map with the companies' strategy (and its cashflow) while leading your
team. You did hands on work when in need and acted as technical role model and teacher for our junior employees.

Thanks to you we are having a decent deployment infrastructure with [Gitlab's](https://gitlab.com) CI/CD integration, which deploys to the [OTC](https://open-telekom-cloud.com).
Our kubernetes cluster is running rock solid and our backup infrastructure is tested and proven to work. As for now we did not hit any issues scaling the plattform.

The \`docker-compose\` infrastructure for our developers helps us to get new coders going in less than a day (at least from the tech side). After experimenting with
numerous processes, we found our way to deploy what we need in time and we based this on principles of [Kanban](https://en.wikipedia.org/wiki/Kanban_(development)) and [Scrum](https://en.wikipedia.org/wiki/Scrum_(software_development)).

Besides the CTO thing, you were responsible for sustainabill's HR, investor relations and most of the legal stuff.
And of course we were sending you to a lot of pitches, when the business development is busy... But, well, that's startup life.

It is sad to see you go, but we understand that needed to start a nerdy adventure. We managed to do a great handover and operations at sustainabill are continuing to run smootly.

Cheers,<br/>
Klaus`,
    address: 'klaus@sustainabill.example.com',
    renderSpecial: null,
    tags: [
      'Leadership',
      'Strategic Planning',
      'Requirements',
      'Road Map Planning',
      'HR',
      'Legal',
      'Dev Ops',
      'Development Processes',
      'Javascript',
      'Vue',
      'HTML',
      'Python',
      'REST',
      'Nginx',
      'Neo4J',
      'K8s',
      'Docker',
      'Janitor'
    ]
  },
  {
    company: 'Pixolus',
    from: 'Barbara (Chief Developer/Architect)',
    image: 'images/pixolus.jpg',
    date: new Date(2019, 11, 8),
    subject: 'Part-time Freelance Developer/Architect Vue',
    content: `Dear Thorsten,

when we urgently needed a new web front-end you supported us from requirement elicitation - internally and together with customers -
via technology and framework selection until the actual implementation of an MVP.

We managed to create a first MVP in in about two months and it was solid enough to be immediately deployed to customers.
Due to its extensible architecture and technology base the front-end is still growing and serving more customers every day.
`,
    address: 'barbara@pixolus.example.com',
    renderSpecial: null,
    tags: ['Vue', 'Requirements Elicitation', 'Chart.js', 'Axios']
  },
  {
    company: 'FreeYou AG',
    from: 'Thomas (Chief Developer/Architect)',
    image: 'images/freeyou.jpg',
    date: new Date(2018, 10, 5),
    subject: 'Parttime Freelance Developer Vue/Angular 05/18 to 09/18',
    content: `Hi Thorsten,

Thanks for supporting us in the time of need. You helped us revamp our website, bootstrap an online insurance calculator and
supported the development team where ever support was needed.

It was good to see how quickly you jumped into Angular Development without too much experience in this particular framework and
we enjoyed our discussions on technical decisions and the stack.
`,
    address: 'thomas@freeyou.example.com',
    renderSpecial: null,
    tags: ['Vue', 'Angular', 'SQL', 'Requirements', 'Technology']
  },
  {
    company: 'Fraunhofer IAIS, Department of Enterprise Information Systems',
    from: 'Christoph Lange (Head of Department)',
    image: 'images/iais.jpg',
    date: new Date(2017, 3, 15),
    subject: 'Senior Researcher from 10/16 to 02/17',
    content: `Thorsten!

at IAIS you estimated and bootstrapped a large industrial data mining project for [Alter Technology](https://www.altertechnology-group.com). You had to

* understand, translate and document customer requirements
* plan milestones
* select an initial team for the project
* do project management
* scout technology`,
    address: 'iais.fraunhofer@example.com',
    renderSpecial: null,
    tags: ['Machine Learning', 'Java', 'Spark', 'Requirements', 'Project Bootstrapping']
  },
  {
    company: 'CHANGE-IT',
    from: 'Rüdiger Seiffefrt (CEO)',
    image: 'images/changeit.jpg',
    date: new Date(2004, 10, 26),
    subject: 'Internship Web Development',
    content: `Hi Thorsten,

your time at CHANGE-IT was a part of your studies at [BRS-U](https://www.h-brs.de). You worked on a Java-based Prototype of a production planning system
for the glasses manufacturer ic-berlin Productions, starting with requirements elicitation together with the team to the technical development.

You evaluated different software products in a very structured way and communicated your decisions clearly towards the team.
    `,
    address: 'ruediger@changeo-it.example.com',
    renderSpecial: null,
    tags: ['Java', 'Hibernate', 'Apache ', 'Technology Scouting', 'Technology Evaluation']
  },
  {
    company: 'TGS Telonic GmbH',
    from: 'Andreas Schlechter (CEO)',
    image: 'images/telonic.jpg',
    date: new Date(2002, 9, 30),
    subject: 'Network professional from 07/01 to 09/02',
    content: `Dear Thorsten,

this was the first workplace after your qualification as technical engineer computer science. You were with us and could immediately
apply your skills configuring Linux and Unix systems, which you trained since you were what - 16 years old?

Besides that you

* configured and ported network management software to new systems
* programmed scripts and C
* hunted and resolved network configuration problems
* worked with consultants and sales to bootstrap new projects
* went to London and Boston for trainings and used the knowledge to internally train other staff
`,
    address: 'telonic@example.com',
    renderSpecial: null,
    tags: ['Network Analytics', 'Predictive Analytics', 'SNMP', 'C', 'Shell Scripting']
  }
]

export const skills: Messages = [
  {
    from: 'Thorsten Merten',
    image: 'images/merten_square.jpg',
    date: new Date(2020, 10, 7),
    subject: 'Skill overview',
    content: `A quick summary of my skills made with [plotly.js](https://plotly.com/javascript).`,
    address: 'thorsten@example.merten.cologne',
    company: '',
    tags: [
      'Requirements',
      'Architecture',
      'Problem Solving',
      'Testing',
      'Docker',
      'K8s',
      'DevOps',
      'Team Work',
      'Tech2People, People2Tech',
      'Full Stack',
      'Usability',
      'UX'
    ],
    renderSpecial: 'SkillOverview'
  },
  // {
  //   from: 'Docker Inc.',
  //   image: 'images/docker.jpg',
  //   date: new Date(2017, 3, 1),
  //   subject: 'Your experience with docker',
  //   content: ``,
  //   address: 'docker@example.com',
  //   company: 'Docker Inc.',
  //   tags: [],
  //   renderSpecial: null
  // },
  {
    from: 'The Dev Ops People',
    image: 'images/k8s.jpg',
    date: new Date(2017, 3, 1),
    subject: '(Dev)Ops Skills',
    content: `Hi Thorsten,

I see you doing a lot of (dev) Ops recently. Most remarkable was

* setting up K8S clusters on Azure
* moving clusters and data seamlessly over to Open Telekom Cloud
* architecting full stack developer environments with docker and compose
  * including graph databases, RDBMs systems and queues
  * new devs are usually up and running in half a day
* planning and implementing test runners and CI/CD pipelines with Gitlab
* implementing a working \`commit\` --> \`issue\` --> \`milestone\` --> \`deployment\` traceability
  * making sure commits are always well formatted with [some lovely regex magic](https://regex101.com/r/KxENTE/3)
* running a solid monitoring of the cloud
* participated integrating MAAS in Canonical's O11y stack
* your team worked out ways to automatically test MAAS images

Cheers,

The devOps Team`,
    address: 'kubernetes@k8s.example.com',
    company: 'Kubernetes Inc.',
    tags: ['docker', 'kubernetes', 'Azure', 'Open Telekom Cloud', 'nginx', 'µwsgi'],
    renderSpecial: null
  },
  {
    from: 'The Dogveloper',
    image: 'images/dogveloper.jpg',
    date: new Date(2017, 3, 1),
    subject: 'Hands-on development',
    content: `Hi Thorsten,

I ran this rough overview of your skills, languages and frameworks through [plotly](https://plotly.com/javascript/) and made some minor style changes.

Tell me what you think,

the dev dog`,
    address: 'devdog@example.com',
    company: '',
    tags: [
      'Software Architecture',
      'Javascript',
      'Vue.js',
      'Angular',
      'HTML',
      'CSS',
      'Vuetify',
      'Bulma',
      'python',
      'Django',
      'Django REST',
      'scikit-learn',
      'nltk',
      'Jupyter',
      'Scripting',
      'Bash',
      'Posix'
    ],
    renderSpecial: 'HandsOnDev'
  }
]

export const spam: Messages = [
  {
    from: 'The Hacker',
    image: 'images/hacker.jpg',
    date: new Date(),
    subject: 'Your inbox is under my control!',
    content: `I hacked your website!

**Transfer €1000 to my bitcoin address** immediately or I will delete all data on your website.`,
    address: 'a-mysterious-hacker@merten.cologne',
    company: null,
    tags: ['Easter Egg'],
    renderSpecial: null
  }
]
