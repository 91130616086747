<template>
  <div>
    <a @click="toggleModal">
      <slot />
    </a>
    <div class="modal" :class="{ 'is-active': modalOpen }">
      <div class="modal-background"></div>
      <div class="modal-card">
        <header class="modal-card-head">
          <p class="modal-card-title">Compose message</p>
          <button class="delete" aria-label="close" @click="modalOpen = false"></button>
        </header>
        <div class="modal-card-body">
          <div class="field">
            <label class="label">From</label>
            <div class="control">
              <input class="input" type="text" value="Thorsten Merten <thorsten@this domain>" disabled />
            </div>
          </div>

          <div class="field">
            <label class="label">Email</label>
            <div class="control has-icons-left has-icons-right">
              <input class="input" :class="{ 'is-danger': !validEmail }" v-model="email" placeholder="Email" />
              <span class="icon is-small is-left">
                <i class="fas fa-envelope"></i>
              </span>
              <span v-if="!validEmail" class="icon is-small is-right">
                <i class="fas fa-exclamation-triangle"></i>
              </span>
            </div>
            <p v-if="!validEmail" class="help is-danger">This email is invalid</p>
          </div>

          <div class="field">
            <label class="label">Subject</label>
            <div class="control">
              <input class="input" type="text" value="" />
            </div>
          </div>

          <div class="field">
            <label class="label">Message</label>
            <div class="control">
              <textarea class="textarea" placeholder="Compose your message here"></textarea>
            </div>
          </div>
        </div>
        <footer class="modal-card-foot">
          <a @click="sent" class="button is-primary">Sent</a>
          <a @click="modalOpen = false" class="button">Cancel</a>
        </footer>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, Ref } from 'vue'
import { Message } from '@/data'

export default defineComponent({
  name: 'NewMessage',
  props: {
    address: {
      type: String,
      default: ''
    }
  },
  setup(props) {
    const modalOpen: Ref<boolean> = ref<boolean>(false)
    const email: Ref<string> = ref<string>(props.address)
    const validEmail = computed(() => {
      // eslint-disable-next-line no-useless-escape
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email.value).toLowerCase())
    })
    const toggleModal = () => {
      modalOpen.value = !modalOpen.value
    }
    const sent = () => {
      alert('No, I cannot let you sent messages in my name, sorry.')
      modalOpen.value = false
    }
    return { modalOpen, toggleModal, sent, email, validEmail }
  }
})
</script>

<style></style>
