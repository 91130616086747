<template>
  <aside class="menu mt-5">
    <p class="menu-label">Mailboxes</p>
    <ul class="menu-list">
      <li>
        <a @click="selectFolder('inbox')" :class="{ 'is-active': selectedFolder === 'inbox' }">
          <span class="icon"><i class="fa fa-inbox"></i></span>
          <span class="name">{{ folder('inbox') }}</span>
        </a>
        <ul>
          <li>
            <a @click="selectFolder('professional')" :class="{ 'is-active': selectedFolder === 'professional' }">
              <span class="icon"><i class="fa fa-star"></i></span>
              <span class="name">{{ folder('professional') }}</span>
            </a>
          </li>
          <li>
            <a @click="selectFolder('academic')" :class="{ 'is-active': selectedFolder === 'academic' }">
              <span class="icon"><i class="fa fa-university"></i></span>
              <span class="name">{{ folder('academic') }}</span>
            </a>
          </li>
          <li>
            <a @click="selectFolder('education')" :class="{ 'is-active': selectedFolder === 'education' }">
              <span class="icon"><i class="fa fa-graduation-cap"></i></span>
              <span class="name">{{ folder('education') }}</span>
            </a>
          </li>
        </ul>
      </li>
      <li>
        <a @click="selectFolder('sent')" :class="{ 'is-active': selectedFolder === 'sent' }">
          <span class="icon"><i class="fa fa-paper-plane"></i></span>
          <span class="name">{{ folder('sent') }}</span>
        </a>
      </li>
      <li>
        <a @click="selectFolder('skills')" :class="{ 'is-active': selectedFolder === 'skills' }">
          <span class="icon"><i class="fa fa-certificate"></i></span>
          <span class="name">{{ folder('skills') }}</span>
        </a>
      </li>
    </ul>
    <p class="menu-label">Links and Social</p>
    <ul class="menu-list">
      <li>
        <a href="https://www.linkedin.com/in/thorsten-merten/" target="_blank">
          <span class="icon"><i class="fab fa-linkedin-in"></i></span>
          <span class="name">LinkedIn</span>
        </a>
      </li>
      <li>
        <a href="https://www.xing.com/profile/Thorsten_Merten6/cv" target="_blank">
          <span class="icon"><i class="fab fa-xing"></i></span>
          <span class="name">Xing</span>
        </a>
      </li>
      <li>
        <a href="https://stackoverflow.com/users/1001118/thorsten" target="_blank">
          <span class="icon"><i class="fab fa-stack-overflow"></i></span>
          <span class="name">Stack Overflow</span>
        </a>
      </li>
      <li>
        <a href="https://github.com/tmerten" target="_blank">
          <span class="icon"><i class="fab fa-github"></i></span>
          <span class="name">Github</span>
        </a>
      </li>
      <li>
        <a href="https://gitlab.com/thorsten.merten" target="_blank">
          <span class="icon"><i class="fab fa-gitlab"></i></span>
          <span class="name">Gitlab</span>
        </a>
      </li>
      <li>
        <a href="https://launchpad.net/~thorsten-merten" target="_blank">
          <span class="icon"><i class="fa fa-rocket"></i></span>
          <span class="name">Launchpad</span>
        </a>
      </li>
    </ul>
  </aside>
</template>

<script lang="ts">
import { defineComponent, watch, ref } from 'vue'
import { routeData } from '../router'
import router from '../router'

export const folderToName: Record<string, string> = {
  inbox: 'Inbox',
  professional: 'Professional Experience',
  academic: 'Academic Experience',
  education: 'Education',
  sent: 'Sent',
  skills: 'Skills'
}

export default defineComponent({
  name: 'SidebarMenu',
  setup(props, { emit }) {
    const params: Record<string, any> = routeData.params
    const selectedFolder = ref('')

    const folder = function (key: string) {
      return folderToName[key]
    }
    const selectFolder = function (folder: string) {
      router.push(`/folder/${folder}`)
    }
    watch(routeData, (routeData) => {
      const params: Record<string, any> = routeData.params
      selectedFolder.value = params.folder
      emit('select-folder', params.folder)
    })
    selectedFolder.value = params.folder
    emit('select-folder', params.folder)
    return { selectFolder, selectedFolder, folder }
  }
})
</script>

<style scoped>
span.icon {
  padding-right: 0.25rem;
}
</style>
