import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// import 'typeface-inter'
import /* webpackChunkName: "typeface-inter" */ 'typeface-inter'

require('./main.scss')

createApp(App).use(store).use(router).mount('#app')
